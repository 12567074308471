import router from "@/router";

const getAuthData = () => {
  const data = localStorage.loginData || sessionStorage.loginData;
  return data && JSON.parse(data);
};

export const getToken = () => {
  return getAuthData()?.access_token;
};

export const isLogged = () => {
  return Boolean(getToken());
};

export const logout = () => {
  localStorage.removeItem("loginData");
  sessionStorage.removeItem("loginData");
  router.push("/");
};

export const login = (data, remember) => {
  if (remember) {
    localStorage.loginData = JSON.stringify(data);
  } else {
    sessionStorage.loginData = JSON.stringify(data);
  }
};

export const isManager = () => {
  return getAuthData()?.user.role_id > 2;
};
